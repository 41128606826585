import React, { useState, useEffect } from 'react';
import './Catchme.css';
import catchHero from './image/hero.png';
import linkdenLogo from './image/linkden.jpg';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';

const CatchMe = () => {
    const [position, setPosition] = useState({ top: '50%', left: '50%' });
    const [hoverText, setHoverText] = useState('CATCH ME');
    const [caught, setCaught] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [isFlying, setIsFlying] = useState(false);

    const handleMouseOver = () => {
        if (!caught) {
            setHoverText('GOTCHA!');
        }
    };

    const handleMouseOut = () => {
        if (!caught) {
            setHoverText('CATCH ME');
        }
    };

    const handleCatch = () => {
        if (!caught) {
            setCaught(true);
            setHoverText('YOU CAUGHT ME!');
            setModalVisible(true);
            setIsFlying(false);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
        setCaught(false);
        setHoverText('CATCH ME');
    };

    const moveCatchBox = () => {
        if (!caught) {
            const maxTop = window.innerHeight - 100;
            const maxLeft = window.innerWidth - 200;
            const randomTop = Math.floor(Math.random() * maxTop);
            const randomLeft = Math.floor(Math.random() * maxLeft);
            setPosition({ top: `${randomTop}px`, left: `${randomLeft}px` });
            setIsFlying(true);
        }
    };

    const handleMouseMove = (e) => {
        if (!caught) {
            const catchBox = document.querySelector('.catch-me-container');
            const rect = catchBox.getBoundingClientRect();
            const distance = Math.hypot(e.clientX - rect.left, e.clientY - rect.top);

            if (distance < 200) {
                moveCatchBox();
            }
        }
    };

    useEffect(() => {
        if (!caught) {
            const intervalId = setInterval(moveCatchBox, 500);
            return () => clearInterval(intervalId);
        }
    }, [caught]);

    // Function to handle email click
    const handleEmailClick = () => {
        const email = "contact@abdelkodoushachani.uk";
        const subject = "I Caught You!";
        const body = "Hello! I caught you and would love to connect. Please let me know how I can reach you.";
        window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    // Function to handle WhatsApp click
    const handleWhatsAppClick = () => {
        const phoneNumber = "+441158883068"; // Replace with your phone number
        const message = "Hello! I caught you and would love to connect.";
        const url = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}&text=${encodeURIComponent(message)}`;
        window.open(url, "_blank");
    };

    return (
        <div className="catch-section" onMouseMove={handleMouseMove}>
            <div 
                className="catch-me-container" 
                style={position}
                onMouseEnter={handleMouseOver} 
                onMouseLeave={handleMouseOut}
                onClick={handleCatch}
            >
                <div className="catch-box">
                    <img 
                        src={catchHero} 
                        alt="Catch Me" 
                        className={`su-catch-image ${isFlying ? 'fly' : ''}`}
                    />
                    <div className="hover-text">{hoverText}</div>
                </div>
            </div>
            {modalVisible && (
                <div className="su-modal">
                    <div className="su-modal-content su-superman-theme">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img 
                           src={linkdenLogo}
                            alt="LinkedIn Profile" 
                            className="profile-pic"
                        />
                        <h2>Hi there! You caught me!</h2>
                        <p>In the future, it won't be so easy to catch me. Stay tuned for updates!</p>
                        <div className="contact-info">
                            <p>Get in touch:</p>
                            <button onClick={handleWhatsAppClick} className="su-contact-button">
                            <FaWhatsapp className="su-icon" />
                                Contact via WhatsApp
                            </button>
                            <button onClick={handleEmailClick} className="su-contact-button">
                            <FaEnvelope className="su-icon" /> 
                                Send Email
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CatchMe;
