import React, { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';
import './Navbar.css';
import logo from './image/logo.png';

function Navbar() {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
    const handleScroll = () => {
      const bannerHeight = document.getElementById('banner').offsetHeight;
      const footerTop = document.getElementById('footer').offsetTop;
      const scrollY = window.scrollY;

      if (scrollY > bannerHeight && scrollY < footerTop) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Render nothing if it's not desktop
  if (!isDesktop) {
    return null;
  }

  return (
    <nav className={`navbar navbar-expand-lg custom-navbar ${showNavbar ? 'show' : ''}`}>
      <a className="logo" href="#"><img src={logo} alt="Logo" /></a>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mx-auto">
          <li className="nav-item">
            <button className="nav-link button-link" onClick={() => scroller.scrollTo('about', { duration: 500, smooth: true, offset: -70 })}>
              About
            </button>
          </li>
          <li className="nav-item">
            <button className="nav-link button-link" onClick={() => scroller.scrollTo('work', { duration: 500, smooth: true, offset: -70 })}>
              Work
            </button>
          </li>
          <li className="nav-item">
            <button className="nav-link button-link" onClick={() => scroller.scrollTo('contact', { duration: 500, smooth: true, offset: -70 })}>
              Contact
            </button>
          </li>
          <li className="nav-item">
            <button className="nav-link button-link" onClick={() => scroller.scrollTo('testimonials', { duration: 500, smooth: true, offset: -70 })}>
              Testimonials
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
