import React, { useEffect, useState } from 'react';
import Banner from './Banner'; // Desktop and tablet version
import BannerMobile from './BannerMobile'; // Mobile version

function RenderBanner() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen size and set the mobile state
    const checkScreenSize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 767); // Mobile screen width is 767px or less
    };

    // Check screen size on component mount
    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener('resize', checkScreenSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div>
      {isMobile ? <BannerMobile /> : <Banner />}
    </div>
  );
}

export default RenderBanner;
