import React, { useEffect, useState } from 'react';
import './SecondaryNavbar.css';
import logo from './image/logo.png';

function SecondaryNavbar() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const handleScroll = () => {
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollPercent = (scrollTop / docHeight) * 100;

    setScrollPercent(scrollPercent);

    if (scrollTop > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initialize scroll position on mount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav className="secondary-navbar">
        <div className="logo">
          <img src={logo} alt="Logo" />
          <a className="tagline">Creative Developer ,Visionary</a>
        </div>
        <a href="#" className="link-tree" onClick={openModal}>
          LINK TREE
        </a>
        <div
          className={`scroll-watcher ${scrolling ? 'active' : ''}`}
          style={{ width: `${scrollPercent}%` }}
        ></div>
      </nav>

       {/* Modal Implementation */}
       {modalVisible && (
        <div className="nav-modal">
          <div className="nav-modal-content">
            <span className="nav-close" onClick={closeModal}>
              &times;
            </span>

            <div className="nav-modal-card">
              <span><a href="https://x.com/thedevbender" >
          X 
        </a></span>
              <span className="arrow-icon">&#8599;</span>
            </div>
            <div className="nav-modal-card">
              <span> <a href="https://medium.com/" >
          Medium 
        </a></span>
              <span className="arrow-icon">&#8599;</span>
            </div>

            <div className="nav-modal-card">
              <span ><a href="https://www.instagram.com/thedevbender/">
          Instagram 
        </a> </span>
              <span className="arrow-icon">&#8599;</span>
            </div>

            <div className="nav-modal-card">
           <span> <a href="https://www.linkedin.com/in/abdelkodous-hachani-583a41272/" >
          Linkedin 
        </a> </span>
              <span className="arrow-icon">&#8599;</span>
            </div>

          </div>
          </div>
      )}
    </>
  );
}

export default SecondaryNavbar;
