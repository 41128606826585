import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import About from './components/About';
import SecondaryNavbar from './components/SecondaryNavbar';
import Footer from './components/Footer';
import SuperBadassBar from './components/SuperBadassBar';
import RenderBanner from './components/RenderBanner';
import CatchMe from './components/Catchme';
import CatchMobile from './components/CatchMobile';
import Projects from './components/Projects';
import ArchiveContainer from './components/ArchiveContainer';
import Testimonials from './components/Testimonials';
import CardContainer from './components/CardContainer';
import OneMoreThink from './components/OneMoreThink';
import Loading from './components/Loading'; // Import your Loading component
import AutoScrollBar from './components/AutoScrollBar'; // Ensure this import is present
import WebflowBanner from './components/WebflowBanner'; // Ensure this import is present
import './components/App.css';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showNavbar, setShowNavbar] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [hasLoaded, setHasLoaded] = useState(false); // Track whether initial load has happened

  useEffect(() => {
    // Check if user has already visited the site
    const hasVisited = localStorage.getItem('hasVisited');
    
    // If they haven't visited, show the loading screen, otherwise skip it
    if (!hasVisited) {
      setLoading(true);
      localStorage.setItem('hasVisited', 'true'); // Set a flag for the session
    } else {
      setLoading(false); // Skip loading if they have visited
    }

    // Set a timeout to hide the loading screen after a certain period (e.g., 3 seconds)
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
      setHasLoaded(true); // Ensure the page knows it has fully loaded
    }, 9000); // Timeout duration: 9 seconds

    // Save scroll position on reload
    const savedPosition = localStorage.getItem('scrollPosition');
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10));
    }

    const handleBeforeUnload = () => {
      localStorage.setItem('scrollPosition', window.scrollY);
    };

    const handleScroll = () => {
      const bannerHeight = document.getElementById('banner').offsetHeight;
      const footerTop = document.getElementById('footer').offsetTop;
      const scrollY = window.scrollY;

      if (scrollY > bannerHeight && scrollY < footerTop) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', () => setIsMobile(window.innerWidth <= 768));

    // Cleanup function to remove event listeners and clear timeout
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', () => setIsMobile(window.innerWidth <= 768));
      clearTimeout(loadingTimeout); // Clear the timeout when component unmounts
    };
  }, []);

  if (loading) {
    return <Loading />; // Display loading screen if loading state is true
  }

  return (
    <div style={{ minHeight: '150vh', display: 'flex', flexDirection: 'column' }}>
      <SecondaryNavbar />
      <Navbar show={showNavbar} /> {/* Pass show prop to Navbar */}
      <div className="content">
        <div id="banner">
          <RenderBanner />
        </div>
        <div id="about">
        <About />
        </div>
        <SuperBadassBar />
        <Projects />
        <div id='work'>
        <ArchiveContainer />
        </div>
        <OneMoreThink />
        <CardContainer />
        <div id='contact'>
          <WebflowBanner />
        {isMobile ? <CatchMobile /> : <CatchMe />}
        </div>
        <AutoScrollBar />
        <div id='testimonials'>
        <Testimonials />
        </div>
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
