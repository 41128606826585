import React from 'react';
import './OneMoreThink.css';  // Unique CSS file for this component
import onemoreImage from './image/onemore.png';  // Importing the image

const OneMoreThink = () => {
  return (
    <div className="onemorethink-container">
      <div className="onemorethink-text-section">
        <h1 className="onemorethink-header-text">One More Thing...</h1>
        <p className="onemorethink-subheader-text">
        Greatness lies in the details. Inspired by visionaries like Steve Jobs, I encourage you to explore, dream, and create. Let’s embark on this journey together!
        </p>
      </div>
      <div className="onemorethink-image-section">
        <img 
          src={onemoreImage} 
          alt="OneMoreThink Showcase" 
          className="onemorethink-product-image"
        />
      </div>
    </div>
  );
};

export default OneMoreThink;
