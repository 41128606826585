import React from 'react';
import './Projects.css'; // Ensure to use your CSS file
import useIntersectionObserver from './useIntersectionObserver'; // Import the custom hook

const Projects = () => {
  // Get the current date
  const currentDate = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString(undefined, options);

  // Use the custom hook for different elements
  const headingVisible = useIntersectionObserver({ target: '.heading h1', threshold: 0.1 });
  const categoryVisible = useIntersectionObserver({ target: '.category', threshold: 0.1 });

  return (
    <section className="projects-section">
      <div className="heading">
        <h1 className={headingVisible ? 'animate' : ''}>Projects</h1>
      </div>
      <hr className="divider" />

      <div className="projects-grid">
        <div className={`category ${categoryVisible ? 'animate' : ''}`}>
          <h3>TIMEFRAME</h3>
          <p>{formattedDate}</p>
        </div>
        <div className={`category ${categoryVisible ? 'animate' : ''}`}>
          <h3>DISCIPLINE</h3>
          <p>Development & Coding</p>
          <p>Entrepreneurial Mindset</p>
          <p>3D Design & Animation</p>
          <p>Art Direction</p>
        </div>
        <div className={`category ${categoryVisible ? 'animate' : ''}`}>
          <h3>TOOLS</h3>
          <p>React</p>
          <p>Python</p>
          <p>Blender</p>
          <p>Unreal Engine</p>
          <p>Visual Content Creation</p>
        </div>
      </div>
    </section>
  );
};

export default Projects;
