import React, { useEffect, useRef, useState } from 'react';
import './Banner.css';
import textImage from './image/text.png'; // Ensure the path is correct
import abdou from './image/abdou.png'; // Your Superman-like image
import scrollGif from './image/scroll.gif'; // Import the scroll gif

function Banner() {
  const bannerRef = useRef(null);
  const imageContainerRef = useRef(null);
  const scrollingTextRef = useRef(null);
  const scrollGifRef = useRef(null);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const bannerElement = bannerRef.current;
    const imageContainerElement = imageContainerRef.current;
    const scrollingTextElement = scrollingTextRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      // Scaling logic for banner
      const scaleFactor = 1 + (scrollPosition / windowHeight) * 0.9;
      const maxScale = 2.2;
      const minScale = 1;
      const bannerScaleValue = Math.min(maxScale, Math.max(minScale, scaleFactor));

      // Scaling logic for text
      const textScaleFactor = 1 - (scrollPosition / windowHeight) * 0.3;
      const maxTextScale = 1;
      const minTextScale = 0.7;
      const textScaleValue = Math.min(maxTextScale, Math.max(minTextScale, textScaleFactor));

      if (scrollPosition > windowHeight * 0.5) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }

      bannerElement.style.transform = `scale(${bannerScaleValue})`;
      imageContainerElement.style.transform = `translate(-50%, -50%) scale(${bannerScaleValue})`;
      scrollingTextElement.style.transform = `translateY(-50%) scale(${textScaleValue})`;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`banner ${isHidden ? 'hidden' : ''}`} ref={bannerRef}>
      {/* Scrolling Text */}
      <div className="scrolling-text-container" ref={scrollingTextRef}>
        <img src={textImage} alt="Scrolling Text" />
      </div>

      {/* Fixed Centered Image */}
      <div className="static-image-container" ref={imageContainerRef}>
        <img src={abdou} alt="Flying Superman Abdou" />
      </div>

      {/* Scroll Down Indicator */}
      <div className="scroll-down-indicator" ref={scrollGifRef}>
        <img src={scrollGif} alt="Scroll Down" />
        <p className="scroll-text">Scroll Down</p>
      </div>
    </div>
  );
}

export default Banner;
