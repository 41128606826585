import React, { useEffect } from 'react';
import './Loading.css';
import logImage from './image/lod.jpg';  // Ensure lod.jpg is in the correct folder

function Loading() {
  useEffect(() => {
    console.log('Loading screen active');
  }, []);

  return (
    <div className="loading-screen">
      <div className="soft-bg-animation"></div>
      <div className="loading-button">
        <span className="loading-dot"></span> loading
      </div>
    </div>
  );
}

export default Loading;
