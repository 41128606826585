import React, { useState, useEffect } from 'react';
import './CatchMobile.css'; // Mobile-specific styles
import catchHero from './image/hero.png'; // Import your image
import linkdenLogo from './image/linkden.jpg'; // Import LinkedIn logo
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa'; // Import react-icons


const CatchMeMobile = () => {
    const [position, setPosition] = useState({ top: '40%', left: '40%' });
    const [hoverText, setHoverText] = useState('CATCH ME');
    const [caught, setCaught] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const handleTouchStart = () => {
        if (!caught) {
            setCaught(true);
            setHoverText('YOU CAUGHT ME!');
            setModalVisible(true);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
        setCaught(false);
        setHoverText('CATCH ME');
    };

    const moveCatchBox = () => {
        if (!caught) {
            const maxTop = window.innerHeight - 150;
            const maxLeft = window.innerWidth - 150;
            const randomTop = Math.random() * maxTop + 'px';
            const randomLeft = Math.random() * maxLeft + 'px';
            setPosition({ top: randomTop, left: randomLeft });
        }
    };

    useEffect(() => {
        if (!caught) {
            const intervalId = setInterval(moveCatchBox, 500);
            return () => clearInterval(intervalId);
        }
    }, [caught]);

    // Function to handle email click
    const handleEmailClick = () => {
        const email = "contact@abdelkodoushachani.uk";
        const subject = "I Caught You!";
        const body = "Hello! I caught you and would love to connect. Please let me know how I can reach you.";
        window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    // Function to handle WhatsApp click
    const handleWhatsAppClick = () => {
        const phoneNumber = "+441158883068"; // Replace with your phone number
        const message = "Hello! I caught you and would love to connect.";
        const url = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}&text=${encodeURIComponent(message)}`;
        window.open(url, "_blank");
    };

    return (
        <div className="catch-section-mobile" onTouchMove={(e) => e.preventDefault()}>
            <div 
                className="catch-me-container-mobile" 
                style={position}
                onTouchStart={handleTouchStart}
            >
                <div className="catch-box">
                    <img src={catchHero} alt="Catch Me" className="catch-image" />
                    <div className="hover-text">{hoverText}</div>
                </div>
            </div>
            {modalVisible && (
                <div className="mb-modal">
                    <div className="mb-modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img 
                            src={linkdenLogo} 
                            alt="LinkedIn Profile" 
                            className="profile-pic"
                        />
                        <h2>Hi there! You caught me!</h2>
                        <p>In the future, it won't be so easy to catch me. Stay tuned for updates!</p>
                        <div className="contact-info">
                            <p>Get in touch:</p>
                            <button onClick={handleWhatsAppClick} className="catch-contact-button">
                            <FaWhatsapp className="catch-icon" />
                                Contact via WhatsApp
                            </button>
                            <button onClick={handleEmailClick} className="catch-contact-button">
                            <FaEnvelope className="catch-icon" /> 
                                Send Email
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CatchMeMobile;

