import React, { useEffect, useRef } from 'react';
import './SuperBadassBar.css';
import superImage from './image/super.png'; // Import the PNG file

const SuperBadassBar = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const container = containerRef.current;

      // Adjust the scroll speed and direction as needed
      if (container) {
        container.style.transform = `translateX(${scrollPosition * -0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="super-badass-bar-container">
      <div className="super-badass-bar-content" ref={containerRef}>
        <span className="super-badass-bar-text">SUPER BADASS </span>
        <img src={superImage} alt="Super" className="super-badass-bar-img" /> {/* Use the PNG */}
        <span className="super-badass-bar-text"> SUPER BADASS</span>
        <img src={superImage} alt="Super" className="super-badass-bar-img" />
        <span className="super-badass-bar-text">SUPER BADASS </span>
        <img src={superImage} alt="Super" className="super-badass-bar-img" />
        <span className="super-badass-bar-text"> SUPER BADASS</span>
        <img src={superImage} alt="Super" className="super-badass-bar-img" />
      </div>
    </div>
  );
};

export default SuperBadassBar;
