import React from 'react';
import './WebflowBanner.css';

const WebflowBanner = () => {
  return (
    <div className="webflow-banner">
      <p>
        If you <strong>catch me</strong>, you can take my contact and inspire me to do something great!
        <span className="gray-text"> — Just like that.</span>
      </p>
    </div>
  );
};

export default WebflowBanner;
