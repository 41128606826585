import React from 'react';
import './Testimonials.css';

const testimonialsData = [
  {
    name: '',
    role: ' @ofeila',
    text: 'I was completely impressed with their professionalism and customer service I highly recommend abdelkodous not only friendly but highly skilled exceeding my expectations every time'
  },
  {
    name: '',
    role: '@pestyoverlord',
    text: 'Absolutely stunning work! Did all the details asked and was easy to work with along the way even through express delivery, very talented will highly recommend'
  },
  {
    name: '',
    role: '@ezdtech',
    text: 'Abdelkodous is an extremely kind, committed and capable freelancer that gave me a great result with an unbeatable cost/result ratio; THANKS.'
  },
  {
    name: '',
    role: '@walteks',
    text: 'abdouhchn is a very good 3d design. Help me a lot with the version of the prototype of my product thx'
  },
  // Add more testimonials here
];

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <div className="quote-section">
        <p>"A true professional with brilliant ideas and fast delivery. Extremely approachable, patient and a very pleasant person. I am sure that we will continue to cooperate."</p>
        <span>University Of Zagreb – zdravkah</span>
      </div>
      <div className="testimonials-section">
        {testimonialsData.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p>{testimonial.text}</p>
            <h4>{testimonial.name}</h4>
            <span>{testimonial.role}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
