// Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ showModal, onClose, title, content }) => {
  if (!showModal) return null; // If modal is not triggered, return null (do not render the modal)

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="modal-close-button" onClick={onClose}>&times;</span>
        <h2 className="modal-title">{title}</h2>
        <p className="modal-body">{content}</p>
      </div>
    </div>
  );
};

export default Modal;
