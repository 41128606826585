import React, { useState } from 'react';
import './Archive.css';
import website from './image/website.jpg'; // تأكد من صحة المسار
import blockchain from './image/blockshain.jpg'; // تأكد من صحة المسار
import offelia from './image/offelia.jpg';
import wooz from './image/wooz.jpg';
import ezdtech from './image/ezdtech.png';
import daypak from './image/daypak.jpg';
import medina from './image/medina.jpg';
import braintchy from './image/braintchy.jpg';



const projects = [
  { 
    name: 'Ancient Life Revived', 
    description: 'Research And Development,UnrealEngine', 
    image: website, 
    email: 'contact@abdelkodoushachani.uk', 
    cc: 'contact@abdelkodoushachani.uk',
    details: 'I would like to inquire more details about the "Ancient Life Revived" project. Could you please provide additional information regarding your branding and website services?'
  },
  {
    name: 'Braintchy',
description: 'Bandana Physics Development, Inspiration, and Website Development',
image: braintchy,
email: 'contact@abdelkodoushachani.uk',
cc: 'contact@abdelkodoushachani.uk',
details: 'I would like to learn more about the "Braintchy" project, which involves developing bandana physics, finding inspiration, and working on website development.'
  },
  { 
   name: 'NFT Blockchain',
   description: '3D Development on Blockchain',
   image: blockchain,
   email: 'contact@abdelkodoushachani.uk',
   cc: 'contact@abdelkodoushachani.uk',
   details: 'I would like to inquire more details about the "NFT Blockchain" project. Could you please provide additional information regarding your 3D and blockchain development services?'
  },
  { 
    name: 'Oefelae',
    description: 'Website Development, Payment Method Solutions, Marketing Assistance (In Progress)',
    image: offelia,
    email: 'contact@abdelkodoushachani.uk',
    cc: 'contact@abdelkodoushachani.uk',
    details: 'I would like to inquire more details about the "Oefelae" project. Could you please provide additional information regarding your website development, payment method solutions, and marketing assistance (currently in progress)'
  },
  {
    name: 'Medina',
    description: 'Web App: Blog Development with Django',
    image: medina,
    email: 'contact@abdelkodoushachani.uk',
    cc: 'contact@abdelkodoushachani.uk',
    details: 'I would like to learn more about the "Medina" project, which involves developing a fully functional blog web app using Django.'
      },
  { 
    name: 'Woozchain',
description: 'Crypto Coin Flash Drive Prototype, User Experience and Simple Demo',
image: wooz,
email: 'contact@abdelkodoushachani.uk',
cc: 'contact@abdelkodoushachani.uk',
details: 'I would like to inquire more details about the "Woozchain" project. Could you please provide additional information regarding the crypto coin flash drive prototype, user experience considerations, and the simple demo implementation?'
  },

  {

    name: 'EZDTech',
  description: 'Game Character Creation, Animation Development with Unreal Engine 4',
  image: ezdtech,
  email: 'contact@abdelkodoushachani.uk',
  cc: 'contact@abdelkodoushachani.uk',
  details: 'I would like to inquire more details about the "EZDTech" project. Could you please provide additional information regarding the game character creation, animation development with Unreal Engine 4, and the assistance provided?'
  },


  {
    name: 'DAYPAK',
  description: '3D Product Fixes in Blender, Website Image Rotation',
  image: daypak,
  email: 'contact@abdelkodoushachani.uk',
  cc: 'contact@abdelkodoushachani.uk',
  details: 'I would like to know more about the "DAYPAK" project, specifically the 3D product fixes in Blender and the website  image rotation issue.'
  },



];

const Archive = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleProjectClick = (index) => {
    setCurrentIndex(index);
  };

  const handleContactClick = () => {
    const project = projects[currentIndex];
    const subject = `Inquiry About the "${project.name}" Project`;
    const body = `Dear [Your Name],

${project.details}

Best regards,
[Your Name]`;

    window.location.href = `mailto:${project.email}?cc=${project.cc}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <div className="archive-container">
      <div className="projects-list">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`project-item ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleProjectClick(index)}
          >
            <div className="project-name">{project.name}</div>
            <div className="project-description">{project.description}</div>
          </div>
        ))}
      </div>
      <div
        className="project-preview"
        style={{ backgroundImage: `url(${projects[currentIndex].image})` }}
      >
        <button className="contact-button" onClick={handleContactClick}>
          <i className="fas fa-lock secure-icon"></i> Contact for Details
        </button>
      </div>
    </div>
  );
};

export default Archive;
