import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './ArchiveMobile.css';

// Example project images
import website from './image/website.jpg';
import blockchain from './image/blockshain.jpg';
import offelia from './image/offelia.jpg';
import wooz from './image/wooz.jpg';
import ezdtech from './image/ezdtech.png';
import daypak from './image/daypak.jpg';
import medina from './image/medina.jpg';
import braintchy from './image/braintchy.jpg';

const projects = [
  { name: 'ANCIENT LIFE REVIVED', description: 'Research And Development,UnrealEngine', image: website, email: 'contact@abdelkodoushachani.uk', cc: 'contact@abdelkodoushachani.uk', details: 'I would like to inquire more details about the "Ancient Life Revived" project. Could you please provide additional information regarding your branding and website services?' },
  { name: 'BRAINTCHY', description: 'Bandana Physics Development, Inspiration, and Website Development', image: braintchy, email: 'contact@abdelkodoushachani.uk', cc: 'contact@abdelkodoushachani.uk', details: 'I would like to learn more about the "Braintchy" project, which involves developing bandana physics, finding inspiration, and working on website development.' },
  { name: 'NFT BLOCKCHAIN', description: '3D Development on Blockchain', image: blockchain, email: 'contact@abdelkodoushachani.uk', cc: 'contact@abdelkodoushachani.uk', details: 'I would like to inquire more details about the "NFT Blockchain" project. Could you please provide additional information regarding your 3D and blockchain development services?' },
  { name: 'OEFELAE', description: 'Website Development, Payment Method Solutions, Marketing Assistance (In Progress)', image: offelia, email: 'contact@abdelkodoushachani.uk', cc: 'contact@abdelkodoushachani.uk', details: 'I would like to inquire more details about the "Oefelae" project. Could you please provide additional information regarding your website development, payment method solutions, and marketing assistance (currently in progress)' },
  { name: 'MEDINA', description: 'Web App: Blog Development with Django', image: medina, email: 'contact@abdelkodoushachani.uk', cc: 'contact@abdelkodoushachani.uk', details: 'I would like to learn more about the "Medina" project, which involves developing a fully functional blog web app using Django.' },
  { name: 'WOOZCHAIN', description: 'Crypto Coin Flash Drive Prototype, User Experience and Simple Demo', image: wooz, email: 'contact@abdelkodoushachani.uk', cc: 'contact@abdelkodoushachani.uk', details: 'I would like to inquire more details about the "Woozchain" project. Could you please provide additional information regarding the crypto coin flash drive prototype, user experience considerations, and the simple demo implementation?' },
  { name: 'EZDTECH', description: 'Game Character Creation, Animation Development with Unreal Engine 4', image: ezdtech, email: 'contact@abdelkodoushachani.uk', cc: 'contact@abdelkodoushachani.uk', details: 'I would like to inquire more details about the "EZDTech" project. Could you please provide additional information regarding the game character creation, animation development with Unreal Engine 4, and the assistance provided?' },
  { name: 'DAYPAK', description: '3D Product Fixes in Blender, Website Image Rotation', image: daypak, email: 'contact@abdelkodoushachani.uk', cc: 'contact@abdelkodoushachani.uk', details: 'I would like to know more about the "DAYPAK" project, specifically the 3D product fixes in Blender and the website’s image rotation issue.' },
];

const ArchiveMobile = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const itemRefs = useRef([]);
  const isScrolling = useRef(false);

  const handleContactClick = () => {
    const project = projects[currentIndex];
    const subject = `Inquiry About the "${project.name}" Project`;
    const body = `Dear [Your Name],\n\n${project.details}\n\nBest regards,\n[Your Name]`;

    window.location.href = `mailto:${project.email}?cc=${project.cc}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const handleProjectClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const itemHeight = itemRefs.current[0]?.clientHeight || 0;
      container.scrollTo({
        top: currentIndex * itemHeight,
        behavior: 'smooth',
      });

      isScrolling.current = true;
      setTimeout(() => {
        isScrolling.current = false;
      }, 500);
    }
  }, [currentIndex]);

  const handleScroll = () => {
    if (containerRef.current && !isScrolling.current) {
      const container = containerRef.current;
      const scrollPosition = container.scrollTop;
      const itemHeight = itemRefs.current[0]?.clientHeight || 0;
      const index = Math.round(scrollPosition / itemHeight);

      if (index !== currentIndex && index >= 0 && index < projects.length) {
        setCurrentIndex(index);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [currentIndex]);

  return (
    <div className="archive-mobile-container" ref={containerRef}>
      <TransitionGroup>
        {projects.map((project, index) => (
          <CSSTransition
            key={index}
            timeout={300}
            classNames="project"
          >
            <div
              className={`project-item-mobile ${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleProjectClick(index)}
              ref={(el) => itemRefs.current[index] = el}
            >
              <div className="project-name-mobile">{project.name}</div>
              <div className="project-description-mobile">{project.description}</div>
              <div className="project-image-mobile" style={{ backgroundImage: `url(${project.image})` }}></div>
              <button className="contact-button-mobile" onClick={handleContactClick}>
                <i className="fas fa-lock secure-icon"></i> Contact for Details
              </button>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

export default ArchiveMobile;
