import React, { useEffect, useState } from 'react';
import './About.css';
import logo from './image/logo.png';

const About = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.querySelector('.about-section');
      const sectionTop = aboutSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Add class to fade in the About section
      setIsVisible(sectionTop < windowHeight * 0.8);
    };

    window.addEventListener('scroll', handleScroll);

    // Initial check
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`about-section ${isVisible ? 'visible' : ''}`}>
      <div className="about-header">
        <h1>About</h1>
      </div>
      <div className="about-content">
        <p>
          <span className="name">Abdelkodous</span> <span className="pronouns">(HE/HIM)</span>  is a creative force on a mission. With a background in software development, he’s diving into freelancing and entrepreneurship, constantly pushing limits. Actively engaged in the open-source community, he explores marketing and video editing, embracing all aspects of project creation.

Based in Tunisia, Hachani draws inspiration from his journey and passion for design, venturing into 3D artistry. Known for a super badass attitude, he crafts experiences that capture attention and resonate deeply. Join this adventure as he transforms ideas into captivating realities!
        </p>
      </div>
      <div className="about-footer">
        <img src={logo} alt="Company Logo" className="company-logo" />
        <div className="company-info">
          <p className="position">Creative Developer and Visionary</p>
          <p className="company">CDV</p>
        </div>
      </div>
    </div>
  );
};

export default About;
