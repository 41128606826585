import React, { useState, useEffect } from 'react';
import './Footer.css'; // Link to your CSS file for styling
import footerLogo from './image/footer.png'; // Assuming your image is in the same directory
import linkdenLogo from './image/linkden.jpg'; // Import the LinkedIn image

const Footer = () => {
  const [localTime, setLocalTime] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const options = { timeZone: 'Africa/Tunis', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
      setLocalTime(now);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000); // Update time every second

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  return (
    <footer className="footer-container">
      <div className="social-icons">
        <img src={linkdenLogo} alt="LinkedIn" className="social-icon" />
        {/* Add Instagram and Facebook icons similarly if needed */}
      </div>
      <div className="footer-logo">
        <img src={footerLogo} alt="Hachani Abdelkodous Logo" className="footer-logo-image" />
      </div>

      <div className="footer-info">
  Made with love <span className="footer-heart">❤️</span> in Tunisia, Tunis.
  <br />
  <span className="footer-time">Local time — {localTime}</span>
</div>
      <div className="footer-buttons">
        <a href="https://www.instagram.com/thedevbender/" target="_blank" rel="noopener noreferrer" className="footer-button">
          Instagram ↗
        </a>
        <a href="https://www.linkedin.com/in/abdelkodous-hachani-583a41272/" target="_blank" rel="noopener noreferrer" className="footer-button">
          Linkedin ↗
        </a>
        <a href="https://medium.com/" target="_blank" rel="noopener noreferrer" className="footer-button">
          Medium ↗
        </a>
        <a href="https://x.com/thedevbender" target="_blank" rel="noopener noreferrer" className="footer-button">
          X ↗
        </a>
      </div>
      <div className="footer-copyright">
        © 2024 ABDELKODOUS HACHANI. ALL RIGHTS RESERVED.
      </div>
    </footer>
  );
};

export default Footer;
