import React, { useState, useRef } from 'react';
import Card from './Card';
import Modal from './Modal';
import './CardContainer.css';
import me from './image/memin.jpeg';
import steve from './image/steve.jpg';
import lion from './image/lion.jpg';
import valve from './image/valve.jpg';

const CardContainer = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });
  const cardContainerRef = useRef(null); // Ref to target the card container

  const openModal = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  // Scroll the container left or right
  const scroll = (direction) => {
    const scrollAmount = 300; // You can adjust this value
    if (direction === 'left') {
      cardContainerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      cardContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="card-container-wrapper">
      {/* Left scroll button */}
      <button className="scroll-btn left" onClick={() => scroll('left')}>{'<'}</button>

      <div className="card-container" ref={cardContainerRef}>
        <Card
          backgroundImage={me}
          title="Tech Evolution: A Personal Journey"
          subtitle="From the First iMac to Modern Development and Innovation"
          content=""
          onPlusClick={() => openModal("Tech Evolution: A Personal Journey", " I explore my journey through the evolution of technology, starting with the iconic first iMac. I reflect on how this groundbreaking device ignited my passion for computers and creativity. I discuss how the iMac’s innovative design and intuitive user experience have shaped my understanding of effective software development. By drawing inspiration from its history, I share how I apply these principles to create engaging user experiences in my projects today. Additionally, I look ahead to future trends that inspire me, encouraging others to leverage technology as a powerful tool for enhancing user engagement and creativity.")}
        />
        <Card
          backgroundImage={steve}
          title="My Approach Inspired by Steve Jobs"
          subtitle="How Iconic Pitches Drive Success"
          content=""
          onPlusClick={() => openModal("How Iconic Pitches Drive Success", "Explore how I apply marketing principles inspired by Steve Jobs’ legendary “1,000 songs in your pocket” pitch to create simple yet impactful strategies. This article highlights how I simplify complex ideas into clear, compelling messages that engage audiences and drive results. Learn how my approach can enhance your projects with straightforward and effective marketing solutions. See how these techniques can make a significant difference in your marketing efforts.")}
        />
        <Card
          backgroundImage={lion}
          title="Badass Freelancer Online"
          subtitle="The Adventure Begins"
          content=""
          onPlusClick={() => openModal("The Adventure Begins", "Imagine embarking on a quest to become a legendary freelancer with just your passion and a laptop. Your journey starts with discovering a map to freelance success, filled with secrets on crafting a standout profile, finding your niche, and setting bold goals. You dive into the adventure, creating a captivating profile, exploring various niches, and connecting with fellow freelancers. Each milestone you achieve transforms you from a novice into a badass freelancer, with lessons learned and connections made along the way.Curious about how I navigated this exciting journey and what I’ve learned? If you want to hear more about my experiences and get some tips, feel free to reach out. Let’s connect and share stories.")}
        />
        <Card
          backgroundImage={valve}
          title="The Gamer Turned Developer"
          subtitle="Supporting the Industry from Both Sides"
          content=""
          onPlusClick={() => openModal("The Gamer Turned Developer", "My journey started at the age of 4, with Half-Life and CS being the first games I ever played. Valve’s iconic games shaped my love for gaming and left a lasting impression. Growing up as a gamer, I learned to appreciate the creativity, passion, and hard work that go into making these immersive worlds. As I transitioned from a gamer to someone with experience in development, my respect for developers grew even deeper. I understand the challenges they face and the dedication required to create games and software. This is why I’ve made it a point to support developers by purchasing games and never cracking software. It’s about respect—for the craft, for the creators, and for the industry.")}
        />
      </div>

      {/* Right scroll button */}
      <button className="scroll-btn right" onClick={() => scroll('right')}>{'>'}</button>

      {/* Modal */}
      <Modal
        showModal={showModal}
        onClose={closeModal}
        title={modalContent.title}
        content={modalContent.content}
      />
    </div>
  );
};

export default CardContainer;
