import React, { useEffect, useRef } from 'react';
import './AutoScrollBar.css';
import witcherGif from './image/witcher.gif'; // Adjust the path as needed

const AutoScrollBar = () => {
  const containerRef = useRef(null);
  
  useEffect(() => {
    const scrollSpeed = 1; // Adjust the scroll speed here
    let scrollAmount = 0;

    const scrollContent = () => {
      const container = containerRef.current;
      if (container) {
        scrollAmount -= scrollSpeed; // Use the fixed scroll speed
        container.style.transform = `translateX(${scrollAmount}px)`;

        // Reset position for continuous loop
        if (Math.abs(scrollAmount) >= container.scrollWidth / 2) {
          scrollAmount = 0;
        }

        requestAnimationFrame(scrollContent); // Use requestAnimationFrame for smoother animation
      }
    };

    scrollContent(); // Start the scrolling

    return () => {
      cancelAnimationFrame(scrollContent); // Clean up animation frame
    };
  }, []);

  return (
    <div className="auto-scroll-bar-container">
      <div className="auto-scroll-bar-content" ref={containerRef}>
        {Array.from({ length: 10 }).map((_, index) => (
          <React.Fragment key={index}>
            <span className="auto-scroll-bar-text">TESTIMONIALS</span>
            <img src={witcherGif} alt="Witcher" className="auto-scroll-bar-gif" />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AutoScrollBar;
