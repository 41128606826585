import React, { useEffect, useState } from 'react';
import Archive from './Archive'; // Import your desktop version
import ArchiveMobile from './ArchiveMobile'; // Import the new mobile version

const ArchiveContainer = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkIsMobile(); 
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <div>
      {isMobile ? <ArchiveMobile /> : <Archive />} {/* Toggle between mobile and desktop */}
    </div>
  );
};

export default ArchiveContainer;
