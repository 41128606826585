import React from 'react';
import './Card.css';

const Card = ({ backgroundImage, title, subtitle, content, onPlusClick }) => {
  return (
    <div className="card" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="card-content">
        <p className="card-subtitle">{subtitle}</p>
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{content}</p>
      </div>

      {/* "+" Button for each card */}
      <button className="card-plus-button" onClick={onPlusClick}>+</button>
    </div>
  );
};

export default Card;
